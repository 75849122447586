import pendoMixin from '@/mixins/pendoMixin';

import ErrorBoundary from 'Components/errorBoundary/errorBoundary';
import ErrorPage from 'Components/errorPage/errorPage';
import NavBarApp from 'Components/navBars/navBarApp/NavBarApp.vue';
import NotificationBar from 'Components/notifications/notificationBar/NotificationBar.vue';

export default {
  components: {
    ErrorBoundary,
    ErrorPage,
    NotificationBar,
    NavBarApp,
  },

  mixins: [pendoMixin],

  computed: {
    navigationBar() { return this.$route.meta.navigationBar; },
  },

  created() {
    this.initializePendo();
  },
};
