<template>
  <div class="navigation-action-bar">
    <div class="navigation-action-bar__action-bar">
      <a
        v-if="activeOrganisation.redirect_url"
        class="navigation-action-bar__action-icon"
        target="_blank"
        rel="noopener noreferrer"
        :href="activeOrganisation.redirect_url"
        aria-label="Redirect to organisation"
      >
        <ActionButtonIcon>
          <ImageMain
            :size="2.4"
            is-circle
            :url="activeOrganisation.redirect_logo"
          />
        </ActionButtonIcon>
      </a>

      <SupportButton
        class="navigation-action-bar__action-icon"
        data-test="support-button"
      />
      <ButtonNotifications
        class="navigation-action-bar__action-icon"
        :new-notifications-count="newNotificationsCount"
        @notifications-are-seen="$emit('notifications-are-seen')"
      />
      <router-link
        class="navigation-action-bar__action-icon"
        :to="routes('chat')"
      >
        <ActionButtonIcon data-test="message">
          <IconComponent name="chat" />
          <BadgeComponent
            v-if="hasNewMessages"
            class="navigation-action-bar__messages-badge"
            :count="newMessagesCount"
            data-test="message-badge"
          />
        </ActionButtonIcon>
      </router-link>
      <ButtonProfile
        v-if="isPatient"
        class="navigation-action-bar__action-button-profile"
      />
      <ButtonOrganisations
        v-else
        class="navigation-action-bar__action-button-organisations"
      />
    </div>
  </div>
</template>

<script src="./navigationActionBar.js"></script>

<style lang="scss">
  .navigation-action-bar {
    flex-direction: column;
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
    }
    .btn-icon > .icon-component {
      display: flex;
      justify-content: center;
      color: $secondary-60;
      svg {
        height: 2rem;
        width: 2rem;
      }
    }

    &__action-bar {
      display: flex;
    }
    &__action-icon {
      align-self: center;
      position: relative;
      display: flex;
      width: 3.6rem;
      height: 3.6rem;
      margin: 0 0.2rem;
    }

    &__action-button-organisations {
      margin-left: 2rem;
      align-self: center;
    }

    &__action-button-profile {
      margin-left: .4rem;
      align-self: center;
    }

    &__messages-badge {
      position: absolute;
      top: -0.2rem;
      right: -0.2rem;
    }
  }
</style>
