<template>
  <div class="button-organisations">
    <button
      v-click-outside="closeMenu"
      class="button-organisations__organisation"
      :title="activeOrganisation.name"
      type="button"
      data-test="profile-menu"
      @click="toggleMenu"
    >
      <div
        v-if="activeOrganisation.logo"
        class="button-organisations__organisation-logo"
        :style="{ backgroundImage: `url(${activeOrganisation.logo})` }"
      />
      <div
        v-else
        class="button-organisations__organisation-default-logo"
      >
        <span>{{ getOrganisationInitials(activeOrganisation.name) }}</span>
      </div>
      <span class="button-organisations__organisation-name truncate">
        {{ activeOrganisation.name }}
      </span>
      <IconComponent
        class="button-organisations__organisation-dropdown-icon"
        :class="{
          'button-organisations__organisation-dropdown-icon--toggled': show,
          'button-organisations__organisation-dropdown-icon--no-clickable':
            !inactiveEnrollments.length,
        }"
        name="dropDown"
      />
    </button>
    <div
      v-if="show"
      class="button-organisations__menu"
    >
      <button
        v-for="({ organisation, id }) in inactiveEnrollments"
        :key="id"
        :data-test="`input-select-option-${organisation.name}`"
        class="button-organisations__menu-item"
        type="button"
        @click="() => selectOrganisation(id)"
      >
        <div
          v-if="organisation.logo"
          class="button-organisations__organisation-logo"
          :style="{ backgroundImage: `url(${organisation.logo})` }"
        />
        <div
          v-else
          class="button-organisations__organisation-default-logo"
        >
          <span>{{ getOrganisationInitials(organisation.name) }}</span>
        </div>
        <span class="button-organisations__organisation-name">
          {{ organisation.name }}
        </span>
      </button>
      <hr
        v-if="inactiveEnrollments.length"
        class="button-organisations__divider"
      >
      <router-link
        class="button-organisations__menu-item button-organisations__menu-item--mobile-hidden"
        data-test="settings-btn"
        :to="routes('settings')"
      >
        <IconComponent name="settings" />
        <span>{{ $t('general.titles.settings') }}</span>
      </router-link>
      <button
        class="button-organisations__menu-item button-organisations__menu-item--mobile-hidden"
        data-test="logout-button"
        type="button"
        @click="() => logout()"
      >
        <IconComponent name="logOut" />
        <span>{{ $t('general.actions.logout') }}</span>
      </button>
    </div>
  </div>
</template>

<script src="./buttonOrganisations.js"></script>

<style lang="scss">
  .button-organisations {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    z-index: map-get($zindex, dropdown);

    &__organisation {
      display: flex;
      border: .1rem solid $neutral-40;
      height: 5.6rem;
      width: 100%;
      align-items: center;
      padding: 1.5rem;
      @include media-breakpoint-up(lg) {
        border-radius: $radius-default;
        min-width: 30rem;
        max-width: 30rem;
        height: 4.8rem;
      }
    }

    &__organisation-logo {
      min-width: 3.2rem;
      max-width: 3.2rem;
      height: 3.2rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: $radius-default;
      border: .1rem solid $neutral-40;
    }

    &__organisation-default-logo {
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: $radius-default;
      border: .1rem solid $neutral-40;
      min-width: 3.2rem;
      max-width: 3.2rem;
      height: 3.2rem;
      background-color: $neutral-60;
      span {
        color: $neutral-0;
      }
    }

    span.button-organisations__organisation-dropdown-icon.icon-component {
      margin-left: auto;
      width: auto;

      @include media-breakpoint-up(lg) {
        margin-left: 0;
      }
      svg {
        width: 1.4rem;
      }
    }

    &__organisation-dropdown-icon--toggled {
      transform: rotate(180deg);
    }

    span.button-organisations__organisation-dropdown-icon--no-clickable {
      display: none;
      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }

    &__organisation-name {
      margin: 0 1rem;
      width: 100%;
      text-align: left;
      @include media-breakpoint-up(lg) {
        width: 100%;
      }
    }

    &__menu {
      align-items: flex-start;
      background-color: $neutral-0;
      border-radius: $radius-default;
      display: flex;
      position: relative;
      flex-direction: column;
      @include media-breakpoint-up(lg) {
        overflow: hidden;
        position: absolute;
        top: 5.8rem;
        right: 0;
        width: 30rem;
        box-shadow: 0 .1rem .4rem rgba($neutral-100, 0.24);
      }
    }

    &__menu-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 1.5rem;
      color: $neutral-80;
      .icon-component {
        width: 2rem;
        margin-right: 2rem;
        color: $neutral-80;
      }
      &:hover {
        background-color: $secondary-10;
      }
      .button-organisations__organisation-logo {
        align-self: flex-start;
      }
      .button-organisations__organisation-default-logo {
        align-self: flex-start;
      }
    }

    &__menu-item--mobile-hidden {
      display: none;
      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }

    &__divider {
      display: flex;
      width: 100%;
      height: .1rem;
      background-color: rgba($neutral-100, .2);
      border: none;
      margin: 0;
      @include media-breakpoint-up(lg) {
        width: calc(100% - 2.8rem);
        margin: 0 1.4rem;
      }
    }
  }
</style>
