const routesMapping = {
  activate_account_prompt: () => '/activate-prompt',
  activate_success: () => '/activate-success',
  activate: () => '/activate',
  care_plan_accept_invitations: () => '/accept-invitations',
  care_plan_create_no_permission: () => '/care-plan-create-no-permission',
  care_plan_create_without_email: () => '/care-plan-create-without-email',
  care_plan_create: () => '/care-plan-create',
  care_plan_participant_admin_add_assignment: (patientId = ':patientId') => `/organisation-patients/${patientId}/add-assignment`,
  care_plan_participant_admin: (carePlanParticipantId = ':carePlanParticipantId') => `/organisation-patients/${carePlanParticipantId}`,
  care_plan_participant_shared: (carePlanParticipantId = ':carePlanParticipantId') => `/patients-shared/${carePlanParticipantId}`,
  care_plan_participant: (carePlanParticipantId = ':carePlanParticipantId') => `/patients/${carePlanParticipantId}`,
  care_plan_participants_admin: () => '/organisation-patients',
  care_plan_participants: () => '/patients',
  change_email_success: (token = ':token') => `/change-email-success/${token}`,
  change_password: (token = ':token') => `/change-password/${token}`,
  chat: () => '/chat',
  coaching: () => '/coaching',
  dashboard: () => '/dashboard',
  data_storage_agreement: () => '/data-storage-agreement',
  deactivated_account: () => '/deactivated-account',
  delete_account: () => '/delete-account',
  deleted_account: () => '/deleted-account',
  diary: () => '/diary',
  error: (errorText = ':errorText?') => `/error/${errorText}`,
  expired_token: () => '/expired-token',
  forgot_password: () => '/forgot-password',
  grant_krachtplan_permission: () => '/grant-krachtplan-permission',
  groups: () => '/groups',
  home: () => '/',
  login_with_token: () => '/login-with-token',
  login: () => '/login',
  monitor_accept_requests: () => '/accept-monitor-requests',
  monitor: () => '/monitors',
  organisation_care_plan_create: () => '/organisation-care-plan-create',
  organisation_slug_care_plan_create: (organisationSlug = ':organisationSlug') => `/organisation/${organisationSlug}/care-plan`,
  page_403: () => '/403',
  page_404: () => '/404',
  page_500: () => '/500',
  patient_add_assignment: (patientId = ':patientId') => `/patients/${patientId}/add-assignment`,
  patient_care_plan: (id = ':id') => `/patient-care-plans/${id}`,
  patient_care_plans: () => '/patient-care-plans',
  performance_indicator_survey: (surveyId = ':surveyId') => `/performance-indicator-survey/${surveyId}`,
  questionnaire_assignment_detail: (organisationId = ':organisationId', questionnaireAssignmentId = ':questionnaireAssignmentId') => `/organisations/${organisationId}/questionnaire-assignment/${questionnaireAssignmentId}`,
  questionnaire_informant_assignment: (questionnaireInformantAssignmentId = ':questionnaireInformantAssignmentId') => `/informant-questionnaire-assignments/${questionnaireInformantAssignmentId}`,
  questionnaire_list: (organisationId = ':organisationId') => `/organisations/${organisationId}/questionnaires`,
  register_options: () => '/register-options',
  register_organisation_slug: (organisationSlug = ':organisationSlug') => `/register/${organisationSlug}`,
  register: () => '/register',
  request_organisation: () => '/request-organisation',
  security_code: () => '/security-code',
  settings: () => '/settings',
  social_environment_treatment: (socialEnvironmentTreatmentId = ':socialEnvironmentTreatmentId') => `/social-environment-treatments/${socialEnvironmentTreatmentId}`,
  sso_validation: () => '/sso',
  treatment_assignment_detail: (organisationId = ':organisationId', treatmentAssignmentId = ':treatmentAssignmentId') => `/organisations/${organisationId}/treatment-assignment/${treatmentAssignmentId}`,
  treatment_assignment_step_payment: (organisationId = ':organisationId', treatmentAssignmentId = ':treatmentAssignmentId', stepId = ':stepId') => `/organisations/${organisationId}/treatment-assignment/${treatmentAssignmentId}/step/${stepId}/payment`,
  treatment_assignment_step_payment_status: (organisationId = ':organisationId', treatmentAssignmentId = ':treatmentAssignmentId', stepId = ':stepId') => `/organisations/${organisationId}/treatment-assignment/${treatmentAssignmentId}/step/${stepId}/payment-status`,
  treatment_assignment_share: (carePlanId = ':carePlanId', treatmentAssignmentId = ':treatmentAssignmentId') => `/care-plan/${carePlanId}/treatment-assignments-share/${treatmentAssignmentId}`,
  treatment_assignment_step: (organisationId = ':organisationId', treatmentAssignmentId = ':treatmentAssignmentId', stepId = ':stepId') => `/organisations/${organisationId}/treatment-assignment/${treatmentAssignmentId}/step/${stepId}`,
  treatment_detail: (organisationId = ':organisationId', treatmentId = ':treatmentId') => `/organisations/${organisationId}/treatments/${treatmentId}`,
  treatment_introduction_step: (organisationId = ':organisationId', treatmentId = ':treatmentId', informationPageId = ':informationPageId') => `/organisations/${organisationId}/treatment/${treatmentId}/information/${informationPageId}`,
  treatment_list: (organisationId = ':organisationId') => `/organisations/${organisationId}/treatments`,
  treatment_by_organisation_list: () => '/treatments-by-organisation',
  treatment_step: (organisationId = ':organisationId', treatmentId = ':treatmentId', stepId = ':stepId') => `/organisations/${organisationId}/treatment/${treatmentId}/step/${stepId}`,
  two_factor_auth: () => '/two-factor-auth',
  user_name_information: () => '/user-name-information',
};

export default (key, ...args) => routesMapping[key](...args);
