import hasNavigationAccess from '@/helpers/hasNavigationAccess';
import isAuth from '@/helpers/isAuth';
import i18n, { loadLanguageAsync } from '@/i18n';
import { dynamicImportedModulesErrorType as errorTypes } from '@/constants/sentryIgnoreErrors';
import store from '@/store/store';
import logout from '@/helpers/logout';
import routes from './routes';

let reloadCounter = 0;
export const handleReload = (error, to = {}) => {
  const reloadPage = (location) => {
    if (location) window.location = location;
    else window.location.reload();
  };

  if (errorTypes.some((e) => error?.message.includes(e)) && reloadCounter <= 3) {
    reloadCounter += 1;
    reloadPage(to?.fullPath);
  }
};

export default (router) => {
  // Temporary fix for outdated dynamic imported modules after building,
  // until main issue get fixed by vite core team
  // For further details,
  // check open ticket (https://github.com/vitejs/vite/issues/11804)
  router.onError(handleReload);

  router.beforeEach(async (to) => {
    await loadLanguageAsync(localStorage.getItem('lang') || 'nl');
    document.title = i18n.global.tc(to.meta.title, 0);

    if (
      !isAuth()
      && to.meta.authRequired
      && to.path !== routes('login')
      && to.path !== routes('two_factor_auth')) {
      const logoutPath = { path: routes('login'), query: { next: to.path } };
      if (localStorage.getItem('session_expires')) {
        await logout({ url: logoutPath });
        return;
      }
      router.push(logoutPath).catch(() => { });
      return;
    }

    if (
      isAuth()
      && store.getters['redirectQueue/redirectionRoute']
      && to.path !== store.getters['redirectQueue/redirectionRoute'].path
    ) {
      router.push(store.getters['redirectQueue/redirectionRoute']).catch(() => { });
      return;
    }

    if (
      isAuth()
      && to.path === routes('login')) {
      router.push({ path: routes('dashboard') }).catch(() => { });
      return;
    }

    const canNavigate = hasNavigationAccess(to.meta);

    if (!canNavigate) router.push({ path: routes('page_403') }).catch(() => { });
  });
};
