export default {
  props: {
    count: {
      type: Number,
      default: 0,
    },
    variant: {
      type: String,
      validator: (prop) => [
        'primary',
        'secondary',
        'info',
        'critical',
        'success',
        'warning',
        'neutral',
      ].includes(prop),
      default: 'primary',
    },
  },
  computed: {
    number() { return this.count <= 99 ? this.count : 99; },
  },
};
