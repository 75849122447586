<template>
  <div class="nav-bar-mobile">
    <router-link
      :to="routes('dashboard')"
      class="nav-bar-mobile__logo"
    >
      <img
        class="nav-bar-mobile__logo-image"
        :src="iconRound"
      >
    </router-link>
    <div class="nav-bar-mobile__right-block">
      <NavigationActionBar
        :new-messages="newMessagesCount"
        :new-notifications-count="newNotificationsCount"
      />
    </div>
    <div class="nav-bar-mobile__left-block">
      <a
        v-if="activeOrganisation.redirect_url"
        class="navigation-action-bar__action-icon"
        target="_blank"
        rel="noopener noreferrer"
        :href="activeOrganisation.redirect_url"
        aria-label="Redirect to organisation"
      >
        <ActionButtonIcon>
          <ImageMain
            :size="2.4"
            is-circle
            :url="activeOrganisation.redirect_logo"
          />
        </ActionButtonIcon>
      </a>
      <ButtonNotifications
        class="nav-bar-mobile__left-block-icon"
        :new-notifications-count="newNotificationsCount"
        @notifications-are-seen="() => $emit('request-unread-notifications')"
      />
      <router-link
        class="nav-bar-mobile__left-block-icon"
        :to="routes('chat')"
      >
        <ActionButtonIcon>
          <IconComponent name="chat" />
          <BadgeComponent
            v-if="newMessagesCount"
            class="nav-bar-mobile__badge"
            :count="newMessagesCount"
          />
        </ActionButtonIcon>
      </router-link>

      <ButtonHamburger
        v-if="canNavigate"
        class="nav-bar-mobile__left-block-icon"
        variant="neutral"
        :is-clicked="menuToggled"
        @click.stop="toggleMenu"
      />
    </div>
    <div
      class="nav-bar-mobile__panel-container transition-fade-down"
      :class="{ 'transition-fade-down--active': menuToggled }"
    >
      <div class="nav-bar-mobile__panel">
        <ul class="nav-bar-mobile__panel-list">
          <li
            v-if="!isPatient"
            class="nav-bar-mobile__panel-item"
          >
            <ButtonOrganisations />
          </li>
          <li
            v-for="(link, index) in links"
            :key="`nav-bar-links--${index}`"
            class="nav-bar-mobile__panel-item"
            @click="closeMenu"
          >
            <router-link
              class="nav-bar-mobile__panel-item-link"
              :to="link.url"
              :target="link.isTargetBlank ? '_blank' : '_self'"
            >
              <IconComponent :name="link.icon" />
              <span class="nav-bar-mobile__panel-item-text">
                {{ $t(...link.labelArgs) }}
              </span>
            </router-link>
          </li>
          <hr class="nav-bar-mobile__split-line">
          <li
            class="nav-bar-mobile__panel-item"
            @click="closeMenu"
          >
            <router-link
              v-if="canNavigate"
              class="nav-bar-mobile__panel-item-link"
              data-test="settings-btn"
              :to="routes('settings')"
            >
              <ActionButtonIcon>
                <IconComponent name="settings" />
                <span class="nav-bar-mobile__panel-item-text">
                  {{ $t('general.titles.settings') }}
                </span>
              </ActionButtonIcon>
            </router-link>
          </li>
          <li
            class="nav-bar-mobile__panel-item"
            @click="closeMenu"
          >
            <SupportButton class="nav-bar-mobile__panel-item-link" />
          </li>
          <li
            class="nav-bar-mobile__panel-item"
            @click="closeMenu"
          >
            <button
              class="nav-bar-mobile__panel-item-link"
              type="button"
              @click="() => logout()"
            >
              <IconComponent name="logOut" />
              <span>{{ $t('general.actions.logout') }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script src="./navBarMobile.js"></script>

<style lang="scss">
.nav-bar-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $app-header-height-mobile;
  padding: 0 2rem;
  background: $neutral-0;
  box-shadow: 0 .3rem 1rem 0 rgba($neutral-40, 0.16);
  @include media-breakpoint-up(lg) {
    display: none;
  }

  &__logo-image {
    width: 3.4rem;
  }

  &__left-block {
    display: flex;
    flex-direction: row;
  }

  &__logo {
    align-self: center;
    display: flex;
    margin-right: $spacing-default * 2;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__left-block-icon {
    align-self: center;
    margin: 0 .5rem;
    position: relative;

    .icon-component {
      display: flex;
      width: 2rem;
      height: 2rem;
      color: $secondary-60;
    }
  }

  &__badge {
    position: absolute;
    top: -0.2rem;
    right: -0.2rem;
  }

  &__right-block {
    display: flex;
    height: 100%;
    flex-direction: row;
    margin-left: auto;
    z-index: map-get($zindex, navigation);
  }

  &__panel {
    box-shadow: 0 .3rem 1rem 0 rgba($neutral-100, 0.11);
    background: $neutral-0;
    flex-direction: column;
    width: 100%;
    display: flex;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__panel-container {
    width: 100%;
    overflow: hidden;
    z-index: map-get($zindex, sidebar);
    top: $app-header-height-mobile;
    left: 0;
    position: absolute;
    display: flex;
  }

  &__panel-list {
    height: 100vh;
    padding: 2rem 0;
    list-style-type: none;
    margin: 0;
  }

  &__panel-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 5.6rem;

    .icon-component {
      align-self: center;
      margin-right: $spacing-default;
    }

    &--buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__panel-item-link {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 2rem;
    text-align: left;
    color: $neutral-80;

    .btn-icon:hover {
      background-color: transparent;
    }

    .btn-icon:active {
      background-color: transparent;
      .icon-component{
        color: inherit;
      }
    }

    span {
      font-size: $font-size-small;
    }

    &.btn-icon:hover {
      background-color: $neutral-0;
    }

    > *:focus {
      outline: 0;
    }

    .btn-icon {
      padding: 0;
    }

    .icon-component {
      margin-right: 2rem;
      display: flex;
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &__panel-item-text {
    text-transform: capitalize;
    color: $secondary-80;
  }

  &__split-line {
    width: calc(100% - 2.8rem);
    height: .1rem;
    background-color: rgba($neutral-100, .2);
    border: none;
    margin: 1.4rem;
  }
}
</style>
