import { mapGetters } from 'vuex';
import routes from '@/router/routes';
import { notificationTypes } from '@/constants/notifications';

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('auth', ['isProfessional']),
  },

  methods: {
    getUrl(notification) {
      const { type, object_id, organisation_id } = notification;
      switch (type) {
        case notificationTypes.ACCEPT_CARE_PLAN:
          return this.isProfessional
            ? routes('care_plan_participant', object_id) : routes('patient_care_plans');

        case notificationTypes.ACCEPT_MONITORS_REQUEST:
        case notificationTypes.REFUSE_MONITORS_REQUEST:
          return `${routes('care_plan_participant', object_id)}?tab=monitor`;

        case notificationTypes.REFUSE_CARE_PLAN:
          return this.isProfessional
            ? `${routes('care_plan_participants')}?status=refused` : routes('patient_care_plans');

        case notificationTypes.ACCEPT_MULTI_PROFESSIONAL_CARE_PLAN:
        case notificationTypes.REFUSE_MULTI_PROFESSIONAL_CARE_PLAN:
        case notificationTypes.ADD_SELF_START_TREATMENT:
        case notificationTypes.PATIENT_NOT_LOGGED:
          return routes('care_plan_participant', object_id);

        case notificationTypes.ARCHIVE_CARE_TAKER:
        case notificationTypes.UPDATE_CARE_PLAN:
          return routes('patient_care_plan', object_id);

        case notificationTypes.DEACTIVATE_CARE_TAKER:
          return `${routes('care_plan_participants')}?status=archived`;

        case notificationTypes.PROFESSIONAL_ADDED_TO_CARE_PLAN:
        case notificationTypes.ADD_SLUG_ORGANISATION_CARE_PLAN:
          return routes('patient_care_plans');

        case notificationTypes.SHARE_TREATMENT:
          return routes('care_plan_participant_shared', object_id);

        case notificationTypes.INFORMANT_COMPLETED_QUESTIONNAIRE:
        case notificationTypes.PATIENT_COMPLETED_QUESTIONNAIRE:
          return (routes('questionnaire_assignment_detail', organisation_id, object_id));

        default:
          return routes(this.isProfessional ? 'care_plan_participants' : 'patient_care_plans');
      }
    },

    getNotificationText(type) {
      switch (type) {
        case notificationTypes.ACCEPT_CARE_PLAN:
          return this.isProfessional
            ? 'general.notifications.acceptCarePlan'
            : 'general.notifications.acceptTreatment';

        case notificationTypes.ACCEPT_MULTI_PROFESSIONAL_CARE_PLAN:
          return 'general.notifications.acceptMultiProfessionalCarePlan';

        case notificationTypes.ACCEPT_MONITORS_REQUEST:
          return 'general.notifications.acceptMonitorRequest';

        case notificationTypes.ADD_SELF_START_TREATMENT:
          return 'general.notifications.addSelfStartTreatment';

        case notificationTypes.ADD_SLUG_ORGANISATION_CARE_PLAN:
          return 'general.notifications.addSlugOrganisationCarePlan';

        case notificationTypes.ARCHIVE_CARE_TAKER:
          return 'general.notifications.archiveCareTaker';

        case notificationTypes.DEACTIVATE_CARE_TAKER:
          return 'general.notifications.deactivateCareTaker';

        case notificationTypes.INFORMANT_COMPLETED_QUESTIONNAIRE:
          return 'general.notifications.informantCompletedQuestionnaire';

        case notificationTypes.PATIENT_COMPLETED_QUESTIONNAIRE:
          return 'general.notifications.patientCompletedQuestionnaire';

        case notificationTypes.PATIENT_NOT_LOGGED:
          return 'general.notifications.patientNotLogged';

        case notificationTypes.PROFESSIONAL_ADDED_TO_CARE_PLAN:
          return 'general.notifications.professionalAddedToCarePlan';

        case notificationTypes.REFUSE_CARE_PLAN:
          return this.isProfessional
            ? 'general.notifications.refuseCarePlan'
            : 'general.notifications.refuseTreatment';

        case notificationTypes.REFUSE_MULTI_PROFESSIONAL_CARE_PLAN:
          return 'general.notifications.refuseMultiProfessionalCarePlan';

        case notificationTypes.REFUSE_MONITORS_REQUEST:
          return 'general.notifications.refuseMonitorRequest';

        case notificationTypes.SHARE_TREATMENT:
          return 'general.notifications.shareTreatment';

        case notificationTypes.UPDATE_CARE_PLAN:
          return 'general.notifications.updateCarePlan';

        default:
          return 'general.notifications.notification';
      }
    },
  },
};
