import IconComponent from 'Components/icons/iconComponent/IconComponent.vue';

export default {
  components: {
    IconComponent,
  },
  methods: {
    getNotificationIcon({ data, type }) {
      if (data && data.icon) return data.icon;

      return type === 'success' ? 'checkmark' : 'rejected';
    },
  },
};
