<template>
  <ActionButtonIcon
    :action="redirectToSupportPage"
    class="support-button"
  >
    <IconComponent name="help" />

    <span class="support-button__text">
      {{ $t('general.words.help') }}
    </span>
  </ActionButtonIcon>
</template>

<script src="./supportButton.js"></script>

<style lang="scss">
  .support-button {
    &.btn-icon:active {
      @include media-breakpoint-down(md) {
        background-color: $neutral-0;
        .icon-component {
          color: inherit;
        }
      }
    }
    .support-button__text {
      color: $neutral-80;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
</style>
