export const dynamicImportedModulesErrorType = [
  // dynamic imported modules typeErrors
  'Failed to fetch dynamically imported module',
  'Unable to preload CSS',
  'Importing a module script failed',
];

export const ignoreErrors = [
  // status codes
  '401', '404', '429', '403',
  ...dynamicImportedModulesErrorType,
];
