<template>
  <div :class="{ [$route.meta.viewClass]: $route.meta.viewClass }">
    <NavBarApp v-if="navigationBar" />
    <div
      class="container"
      :class="{ 'container--website': !navigationBar }"
    >
      <NotificationBar />
      <ErrorPage>
        <ErrorBoundary>
          <router-view :key="$route.path" />
        </ErrorBoundary>
      </ErrorPage>
    </div>
    <div id="modal" />
  </div>
</template>

<script src="./routerView.js" />

<style lang="scss">
  .container {
    margin-top: 1.6rem;

    &--website {
      min-height: 100vh;
      margin-top: 0;
    }
  }
</style>
