export const ADD_ROUTE = 'ADD_ROUTE';
export const REMOVE_FIRST = 'REMOVE_FIRST';

const initialStateRedirectQueue = () => ({
  redirectionQueue: [],
  redirectionRoute: null,
});

const redirectQueue = {
  namespaced: true,
  state: initialStateRedirectQueue(),

  getters: {
    redirectionQueue: (state) => state.redirectionQueue,
    firstInQueue: (state) => state.redirectionQueue[0],
    redirectionQueueEmpty: (state) => !state.redirectionQueue.length,
    redirectionRoute: (state) => state.redirectionRoute || state.redirectionQueue[0],
  },

  mutations: {
    ADD_ROUTE: (state, payload) => {
      state.redirectionQueue.push(payload);
    },
    REMOVE_FIRST: (state) => {
      state.redirectionRoute = state.redirectionQueue.shift();
    },
  },

  actions: {
    addRoute({ commit }, route) {
      commit(ADD_ROUTE, route);
    },
    removeFirst({ commit }) {
      commit(REMOVE_FIRST);
    },
  },
};

export { initialStateRedirectQueue, redirectQueue };
