import { defineAsyncComponent } from 'vue';

export const iconNames = [
  'add',
  'add_simple',
  'administration',
  'alarm',
  'anchor',
  'arrow',
  'arrowLargeDown',
  'arrowLargeUp',
  'avatar',
  'bell',
  'calendar',
  'call',
  'carePlan',
  'carePlanHealth',
  'chat',
  'check',
  'checkEmpty',
  'checkFilled',
  'checkmark',
  'clock',
  'close',
  'coaching',
  'copy',
  'cross',
  'diary',
  'dots',
  'doubleArrow',
  'download',
  'downloadFile',
  'dropDown',
  'dropDownInput',
  'edit',
  'error',
  'errorLight',
  'eye',
  'eyeVisible',
  'file',
  'filterMenu',
  'hamburger',
  'help',
  'hidden',
  'home',
  'information',
  'invitePerson',
  'key',
  'library',
  'lightBulb',
  'lock',
  'logOut',
  'message',
  'minus',
  'monitor',
  'network',
  'newConversation',
  'newTab',
  'next',
  'organisation',
  'paperclip',
  'patients',
  'pencil',
  'pin',
  'plus',
  'power',
  'professionals',
  'profile',
  'questionnaire',
  'questionnaireCircle',
  'rejected',
  'remove',
  'removeCircleOutline',
  'reply',
  'save',
  'search',
  'security',
  'send',
  'settings',
  'share',
  'sharePeople',
  'smileyBad',
  'smileyGood',
  'smileyGreat',
  'smileyOk',
  'smileySerious',
  'spinner',
  'star',
  'starEmpty',
  'step1',
  'step2',
  'step3',
  'thumbUp',
  'trash',
  'treatment',
  'upload',
  'viewBig',
  'viewMedium',
  'viewSmall',
  'visible',
  'zoomIn',
  'zoomOut',
];

export default {
  components: {
    add: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/add.svg?component')),
    add_simple: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/add_simple.svg?component')),
    administration: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/administration.svg?component')),
    alarm: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/alarm.svg?component')),
    anchor: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/anchor.svg?component')),
    arrow: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/arrow.svg?component')),
    arrowLargeDown: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/arrow-large-down.svg?component')),
    arrowLargeUp: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/arrow-large-up.svg?component')),
    avatar: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/avatar.svg?component')),
    bell: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/bell.svg?component')),
    calendar: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/calendar.svg?component')),
    call: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/call.svg?component')),
    carePlan: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/care-plan.svg?component')),
    carePlanHealth: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/care-plan-health.svg?component')),
    chat: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/chat.svg?component')),
    check: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/check.svg?component')),
    checkEmpty: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/check-empty.svg?component')),
    checkFilled: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/check-filled.svg?component')),
    checkmark: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/checkmark.svg?component')),
    clock: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/clock.svg?component')),
    close: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/close.svg?component')),
    coaching: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/coaching.svg?component')),
    copy: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/copy.svg?component')),
    cross: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/cross.svg?component')),
    diary: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/diary.svg?component')),
    dots: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/dots.svg?component')),
    doubleArrow: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/double-arrow.svg?component')),
    download: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/export.svg?component')),
    downloadFile: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/download-file.svg?component')),
    dropDown: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/drop-down.svg?component')),
    dropDownInput: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/drop-down-input.svg?component')),
    edit: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/edit.svg?component')),
    error: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/error.svg?component')),
    errorLight: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/error-light.svg?component')),
    eye: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/eye.svg?component')),
    eyeVisible: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/eye-visible.svg?component')),
    file: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/file.svg?component')),
    filterMenu: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/filter-menu.svg?component')),
    hamburger: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/hamburger.svg?component')),
    help: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/help.svg?component')),
    hidden: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/hidden.svg?component')),
    home: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/home.svg?component')),
    information: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/information.svg?component')),
    invitePerson: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/invite-person.svg?component')),
    key: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/key.svg?component')),
    library: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/library.svg?component')),
    lightBulb: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/light-bulb.svg?component')),
    lock: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/lock.svg?component')),
    logOut: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/log-out.svg?component')),
    message: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/message.svg?component')),
    minus: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/minus.svg?component')),
    monitor: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/monitor.svg?component')),
    network: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/network.svg?component')),
    newConversation: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/new-conversation.svg?component')),
    newTab: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/new-tab.svg?component')),
    next: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/next.svg?component')),
    organisation: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/organisation.svg?component')),
    paperclip: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/paperclip.svg?component')),
    patients: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/patients.svg?component')),
    pencil: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/pencil.svg?component')),
    pin: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/pin.svg?component')),
    plus: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/plus.svg?component')),
    power: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/power.svg?component')),
    professionals: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/professionals.svg?component')),
    profile: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/profile.svg?component')),
    questionnaire: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/questionnaire.svg?component')),
    questionnaireCircle: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/questionnaire_circle.svg?component')),
    rejected: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/rejected.svg?component')),
    remove: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/remove.svg?component')),
    removeCircleOutline: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/remove-circle-outline.svg?component')),
    reply: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/reply.svg?component')),
    save: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/save.svg?component')),
    search: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/search.svg?component')),
    security: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/security.svg?component')),
    send: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/send.svg?component')),
    settings: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/settings.svg?component')),
    share: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/share.svg?component')),
    sharePeople: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/share-people.svg?component')),
    smileyGreat: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/smiley-great.svg?component')),
    smileyGood: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/smiley-good.svg?component')),
    smileyOk: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/smiley-ok.svg?component')),
    smileyBad: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/smiley-bad.svg?component')),
    smileySerious: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/smiley-serious.svg?component')),
    spinner: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/spinner.svg?component')),
    star: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/star.svg?component')),
    starEmpty: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/star-empty.svg?component')),
    step1: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/step-1.svg?component')),
    step2: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/step-2.svg?component')),
    step3: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/step-3.svg?component')),
    thumbUp: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/thumb-up.svg?component')),
    trash: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/trash.svg?component')),
    treatment: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/treatment.svg?component')),
    upload: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/upload.svg?component')),
    viewBig: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/view-big.svg?component')),
    viewMedium: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/view-medium.svg?component')),
    viewSmall: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/view-small.svg?component')),
    visible: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/visible.svg?component')),
    zoomIn: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/zoom-in.svg?component')),
    zoomOut: defineAsyncComponent(() => import('Assets/icons/_icomoon-export/SVG/zoom-out.svg?component')),
  },
  props: {
    name: {
      type: String,
      default: '',
      required: true,
      validator: (prop) => iconNames.includes(prop),
    },
  },
};
