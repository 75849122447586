<template>
  <div class="error-message">
    <div class="error-message__inner">
      <IconComponent
        class="error-message__icon"
        name="errorLight"
      />
      <span class="error-message__text"> {{ $t('general.errors.component') }}</span>
    </div>
  </div>
</template>

<script src="./errorMessage.js" />

<style lang="scss">
  .error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: $spacing-default;
    background: $neutral-10;
    text-align: center;
    color: $neutral-60;

    &__icon {
      margin-right: $spacing-default * .5;
    }
  }
</style>
