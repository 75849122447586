<template>
  <component
    :is="isButton ? 'button' : 'div'"
    class="profile-avatar"
    :class="[
      `profile-avatar--${variant}`,
      {
        'profile-avatar--button': isButton,
        'profile-avatar--small': isSmall,
        'profile-avatar__image': url,
      },
    ]"
    :alt="imageAlt"
    :style="style"
    @click="emitEvent"
  >
    <div v-if="!url">
      <span
        v-if="initials"
        class="profile-avatar__initials"
      >
        {{ initials }}
      </span>
      <IconComponent
        v-else
        class="profile-avatar__avatar"
        name="profile"
      />
    </div>
  </component>
</template>

<script src="./profileAvatar.js"></script>

<style lang="scss">
  .profile-avatar {
    border-radius: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: .2rem solid $neutral-0;

    &--button {
      &:hover {
        border-color: $neutral-20;
      }
    }

    &--small {
      .icon-component {
        width: 2rem;
      }
    }

    &__avatar {
      display: flex;
      color: $neutral-0;

      &.icon-component {
        color: $neutral-0;
      }
    }

    &__initials {
      font-weight: $font-weight-semi-bold;
      color: $neutral-0;
      font-size: $font-size-small;
    }

    &--primary {
      background: $primary-60;
    }
    &--secondary {
      background: $secondary-60;
    }
    &--info {
      background: $info-60;
    }
    &--warning {
      background: $warning-60;
    }
    &--success {
      background: $success-60;
    }
    &--critical {
      background: $critical-20;
    }
    &--neutral {
      background: $secondary-80;
    }
  }
</style>
