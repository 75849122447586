import { mapGetters } from 'vuex';

import IconComponent from 'Components/icons/iconComponent/IconComponent.vue';
import ProfileAvatar from 'Components/profileAvatar/ProfileAvatar.vue';

import routes from '@/router/routes';
import logout from '@/helpers/logout';

export default {
  components: {
    IconComponent,
    ProfileAvatar,
  },

  data() {
    return {
      routes,
      show: false,
      logout,
    };
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },

  methods: {
    openMenu() {
      this.show = true;
    },
    closeMenu() {
      this.show = false;
    },
  },
};
