import isAuth from '@/helpers/isAuth';

export default async (url, options = {}) => {
/**
 * @param {string} url - channel's url to connect
 * @param {object} <options>
 * @param {boolean} [options.reconnect = false] - to allow reconnection
 * @param {number} [options.reconnectAttempts = 3] - times of reconnect attempts (by default 3)
*/
  const { reconnect = false, reconnectAttempts = 3 } = options;
  let reconnectCount = 0;
  const target = {
    socket: null,
    url,
    onopen: () => {},
    onmessage: () => {},
    onclose: () => {},
    readyState: () => target.socket.readyState,
    close: (args) => target.socket.close(args),
    send: (args) => {
      if (target.socket.readyState !== 1) return;
      target.socket.send(args);
    },
  };

  const onSocketOpen = (args) => {
    target.onopen(args); // fire event on open to be handle on onopen method
  };

  const onSocketMessage = (args) => {
    target.onmessage(args); // fire event on message to be handle on onmessage method
  };

  const onSocketClose = async (args) => {
    // websocket closeEvent code 1000 is the default after firing close method
    const { code } = args;
    if (reconnect && code !== 1000 && reconnectCount < reconnectAttempts && isAuth()) {
      reconnectCount += 1;
      // eslint-disable-next-line no-use-before-define
      await connect();
      return;
    }
    reconnectCount = 0;
    target.onclose(args); // fire event on close to be handle on onclose method
  };

  const createWebSocket = (socketUrl) => {
    const urlParsed = new URL(socketUrl, window.location.href);
    urlParsed.protocol = urlParsed.protocol.replace('http', 'ws');
    return new WebSocket(urlParsed);
  };

  const connect = () => {
    target.socket = createWebSocket(target.url);
    target.socket.onopen = onSocketOpen;
    target.socket.onmessage = onSocketMessage;
    target.socket.onclose = onSocketClose;
  };

  await connect();
  return target;
};
