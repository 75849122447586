<template>
  <div
    class="image-main"
  >
    <div
      v-if="url"
      class="image-main__image"
      :style="style"
    />
    <IconComponent
      v-else
      :style="{ width: `${size}rem` }"
      :name="type"
    />
  </div>
</template>

<script src="./imageMain.js"></script>

<style lang="scss">
  .image-main{
    &__image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
</style>
