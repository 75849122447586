function isFailure(error) {
  const { status } = error.response;
  return status >= 500 && status < 600;
}

export default class RequestContext {
  #http;

  #config;

  #resolve;

  #reject;

  constructor(http, config, resolve, reject) {
    this.#http = http;
    this.#config = config;
    this.#resolve = resolve;
    this.#reject = reject;
  }

  attemptRequest() {
    return new Promise((resolve, reject) => {
      let failingError = null;

      this.#http.request(this.#config)
        .then((result) => { this.#resolve(result); })
        .catch((error) => {
          this.#reject(error);
          failingError = isFailure(error) ? error : null;
        })
        .finally(() => {
          if (failingError) {
            reject(failingError);
          } else {
            resolve();
          }
        });
    });
  }

  failRequest(error) {
    this.#reject(error);
  }
}
