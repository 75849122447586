import LoaderComponent from 'Components/loaderComponent/LoaderComponent.vue';

export default {
  components: {
    LoaderComponent,
  },
  props: {
    action: {
      type: Function,
      default: () => {},
    },
    value: {
      type: String,
    },
    type: {
      type: String,
      default: 'button',
    },
    name: {
      type: String,
      default: '',
    },
    isSecondary: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      validator: (prop) => [
        'primary',
        'secondary',
        'info',
        'critical',
        'success',
        'warning',
        'neutral',
        'accent',
      ].includes(prop),
      default: 'primary',
    },
    tabIndex: {
      type: String,
    },
    to: {
      type: [String, Object],
      default: undefined,
    },
  },
};
