export const notificationCommands = {
  FETCH_UNREAD_MESSAGES: 'fetch_unread_messages',
  FETCH_UNREAD_NOTIFICATIONS: 'fetch_unread_notifications',
};
export const notificationTypes = {
  ACCEPT_CARE_PLAN: 'accept_care_plan',
  ACCEPT_MULTI_PROFESSIONAL_CARE_PLAN: 'accept_multi_professional_care_plan',
  ACCEPT_MONITORS_REQUEST: 'accept_monitors_request',
  ADD_SELF_START_TREATMENT: 'add_self_start_treatment',
  ADD_SLUG_ORGANISATION_CARE_PLAN: 'add_slug_organisation_care_plan',
  ARCHIVE_CARE_TAKER: 'archive_care_taker',
  DEACTIVATE_CARE_TAKER: 'deactivate_care_taker',
  INFORMANT_COMPLETED_QUESTIONNAIRE: 'informant_completed_questionnaire',
  PATIENT_COMPLETED_QUESTIONNAIRE: 'patient_completed_questionnaire',
  PATIENT_NOT_LOGGED: 'patient_not_logged',
  PROFESSIONAL_ADDED_TO_CARE_PLAN: 'professional_added_to_care_plan',
  REFUSE_CARE_PLAN: 'refuse_care_plan',
  REFUSE_MULTI_PROFESSIONAL_CARE_PLAN: 'refuse_multi_professional_care_plan',
  REFUSE_MONITORS_REQUEST: 'refuse_monitors_request',
  SHARE_TREATMENT: 'share_treatment',
  UNREAD_MESSAGES: 'unread_messages',
  UNREAD_NOTIFICATIONS: 'unread_notifications',
  UPDATE_CARE_PLAN: 'update_care_plan',
};
