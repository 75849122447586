<template>
  <button
    type="button"
    class="button-hamburger"
  >
    <div class="button-hamburger__hamburger">
      <div
        class="button-hamburger__hamburger-inner"
        :class="{
          'button-hamburger__hamburger--animated': isClicked,
        }"
      />
    </div>
  </button>
</template>

<script src="./buttonHamburger.js"></script>

<style lang="scss">
  $bar-width: 2.4rem;
  $bar-height: .3rem;
  $bar-spacing: 1rem;

  .button-hamburger__hamburger {
    width: $bar-width;
    height: $bar-height + $bar-spacing*2;
    cursor: pointer;
  }

  .button-hamburger__hamburger-inner,
  .button-hamburger__hamburger-inner:after,
  .button-hamburger__hamburger-inner:before {
    width: $bar-width;
    height: $bar-height;
    border-radius: $radius-xl;
  }

  .button-hamburger__hamburger-inner {
    position: relative;
    transform: translateY($bar-spacing);
    background: $neutral-80;
    transition: all 0ms 150ms;
    &.button-hamburger__hamburger--animated {
      background: transparent;
    }
  }

  .button-hamburger__hamburger-inner:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    background: $neutral-80;
    transition: bottom 150ms 150ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 150ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .button-hamburger__hamburger-inner:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    background: $neutral-80;
    transition: top 150ms 150ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 150ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .button-hamburger__hamburger-inner.button-hamburger__hamburger--animated:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 150ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 150ms 150ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .button-hamburger__hamburger-inner.button-hamburger__hamburger--animated:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 150ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 150ms 150ms cubic-bezier(0.23, 1, 0.32, 1);
  }
</style>
