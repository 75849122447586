export const ADD_QUESTIONNAIRE_INFORMANT_ASSIGNMENT = 'ADD_QUESTIONNAIRE_INFORMANT_ASSIGNMENT';
export const DELETE_TREATMENT_ASSIGNMENT = 'DELETE_TREATMENT_ASSIGNMENT';
export const DELETE_QUESTIONNAIRE_ASSIGNMENT = 'DELETE_QUESTIONNAIRE_ASSIGNMENT';
export const RESET_ASSIGNMENTS = 'RESET_ASSIGNMENTS';
export const ADD_QUESTIONNAIRE_ASSIGNMENT = 'ADD_QUESTIONNAIRE_ASSIGNMENT';
export const SET_TREATMENT_ASSIGNMENTS = 'SET_TREATMENT_ASSIGNMENTS';
export const UPDATE_QUESTIONNAIRE_ASSIGNMENT_DATES = 'UPDATE_QUESTIONNAIRE_ASSIGNMENT_DATES';
export const UPDATE_QUESTIONNAIRE_ASSIGNMENT_INFORMANTS_SELECTED = 'UPDATE_QUESTIONNAIRE_ASSIGNMENT_INFORMANTS_SELECTED';

const initialStateCarePlanCreate = () => ({
  questionnaireAssignments: [],
  treatmentAssignments: [],
});

const carePlanCreate = {
  namespaced: true,
  state: initialStateCarePlanCreate(),

  getters: {
    questionnaireAssignments: (state) => state.questionnaireAssignments,
    treatmentAssignments: (state) => state.treatmentAssignments,
    treatmentAssignmentIds: (state) => state.treatmentAssignments
      .map((treatment) => treatment.value),
  },

  mutations: {
    ADD_QUESTIONNAIRE_ASSIGNMENT: (state, newQuestionnaireAssignments) => {
      state.questionnaireAssignments.push(newQuestionnaireAssignments);
    },
    ADD_QUESTIONNAIRE_INFORMANT_ASSIGNMENT: (state, questionnaireAssignment) => {
      state.questionnaireAssignments = state.questionnaireAssignments
        .filter((questionnaire) => (questionnaire.value === questionnaireAssignment.value
          ? questionnaireAssignment : questionnaire));
    },
    DELETE_QUESTIONNAIRE_ASSIGNMENT: (state, id) => {
      state.questionnaireAssignments = state.questionnaireAssignments
        .filter((questionnaire) => questionnaire.id !== id);
    },
    DELETE_TREATMENT_ASSIGNMENT: (state, id) => {
      state.treatmentAssignments = state.treatmentAssignments
        .filter((treatment) => treatment.value !== id);
    },
    RESET_ASSIGNMENTS: (state) => {
      state.questionnaireAssignments = [];
      state.treatmentAssignments = [];
    },
    SET_TREATMENT_ASSIGNMENTS: (state, newTreatmentAssignments) => {
      state.treatmentAssignments = [...newTreatmentAssignments];
    },
    UPDATE_QUESTIONNAIRE_ASSIGNMENT_DATES: (state, { executionDates, index }) => {
      state.questionnaireAssignments[index].executionDates = executionDates;
    },
    UPDATE_QUESTIONNAIRE_ASSIGNMENT_INFORMANTS_SELECTED: (state, { informants, index }) => {
      state.questionnaireAssignments[index].informants = informants;
    },
  },

  actions: {
    addQuestionnaireAssignment: ({ commit }, questionnaire) => {
      commit(ADD_QUESTIONNAIRE_ASSIGNMENT, questionnaire);
    },
    addQuestionnaireInformantAssignment: ({ commit }, questionnaireAssignment) => {
      commit(ADD_QUESTIONNAIRE_INFORMANT_ASSIGNMENT, questionnaireAssignment);
    },
    deleteQuestionnaireAssignment: ({ commit }, id) => {
      commit(DELETE_QUESTIONNAIRE_ASSIGNMENT, id);
    },
    deleteTreatmentAssignment: ({ commit }, id) => {
      commit(DELETE_TREATMENT_ASSIGNMENT, id);
    },
    resetAssignments: ({ commit }) => {
      commit(RESET_ASSIGNMENTS);
    },
    setTreatmentAssignments: ({ commit }, treatmentAssignments) => {
      commit(SET_TREATMENT_ASSIGNMENTS, treatmentAssignments);
    },
    updateQuestionnaireAssignmentDates: ({ commit }, payload) => {
      // payload = { executionDates, index }
      commit(UPDATE_QUESTIONNAIRE_ASSIGNMENT_DATES, payload);
    },
    updateQuestionnaireAssignmentInformantsSelected: ({ commit }, payload) => {
      // payload = { informants, index }
      commit(UPDATE_QUESTIONNAIRE_ASSIGNMENT_INFORMANTS_SELECTED, payload);
    },
  },
};

export { initialStateCarePlanCreate, carePlanCreate };
