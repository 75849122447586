import IconComponent from 'Components/icons/iconComponent/IconComponent.vue';

export default {
  props: {
    isButton: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    remSize: {
      type: Number,
      default: 4,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      validator: (prop) => [
        'primary',
        'secondary',
        'info',
        'critical',
        'success',
        'warning',
        'neutral',
      ].includes(prop),
      default: 'primary',
    },
    url: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
  },

  components: {
    IconComponent,
  },

  computed: {
    initials() {
      const { first_name, last_name } = this.user;
      if (this.name) return this.getParsedName();
      if (!first_name || !last_name) return '';
      return `${first_name.charAt(0).toUpperCase()}${last_name.charAt(0).toUpperCase()}`;
    },

    size() {
      return `${(this.isSmall ? 3 : this.remSize) + 0.8}rem`;
    },

    style() {
      return {
        height: this.size,
        width: this.size,
        minWidth: this.size,
        borderRadius: '50rem',
        backgroundImage: this.url ? `url(${this.url})` : '',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      };
    },

    imageAlt() {
      return this.alt ? this.alt : null;
    },
  },

  methods: {
    getParsedName() {
      const nameList = this.name.split(' ');
      return nameList.reduce((initials, name, index) => {
        if (index >= 2) return initials;
        return `${initials}${name.charAt(0).toUpperCase()}`;
      }, '');
    },

    emitEvent(target) {
      this.$emit('click', target);
    },
  },
};
