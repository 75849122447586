import IconComponent from 'Components/icons/iconComponent/IconComponent.vue';

export default {
  components: {
    IconComponent,
  },

  props: {
    links: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      activeLineHidden: false,
      activeLineWidth: 0,
      activeLineOffset: 0,
    };
  },

  watch: {
    $route(route) {
      if (this.links.some(({ url }) => route.path.includes(url))) this.moveActiveLine();
      else this.resetActiveLine();
    },
  },

  mounted() {
    setTimeout(() => {
      // wait for child components are fully rendered
      this.moveActiveLine();
    }, 1000);

    // Fix for Safari if window resizes
    window.addEventListener('resize', () => {
      this.moveActiveLine();
    });
  },

  methods: {
    moveActiveLine() {
      const [linkRef] = this.$refs[this.$route.path] || [];
      if (!linkRef) return;
      const element = linkRef.$el;
      if (element && element.clientWidth) {
        this.activeLineHidden = false;
        this.activeLineWidth = element.clientWidth;
        this.activeLineOffset = element.offsetLeft;
      } else if (element) {
        this.resetActiveLine();
      }
    },

    resetActiveLine() {
      this.activeLineHidden = true;
      this.activeLineWidth = 0;
      this.activeLineOffset = 0;
    },
  },
};
