import { h } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import Page403 from '@/views/errors/403/403View.vue';
import Page404 from '@/views/errors/404/404View.vue';
import Page500 from '@/views/errors/500/500View.vue';

export default {
  components: {
    Page403,
    Page404,
    Page500,
  },
  computed: {
    ...mapGetters('errors', ['errorStatusCode']),
    errorIsRaised() {
      return this.errorStatusCode && this.errorStatusCode !== 401;
    },
  },

  watch: {
    $route() {
      if (!this.errorIsRaised) return;
      this.setErrorStatusCode(null);
    },
  },

  methods: {
    ...mapActions('errors', ['setErrorStatusCode']),

    renderErrorPage() {
      switch (this.errorStatusCode) {
        case 403:
          return Page403;
        case 404:
          return Page404;
        case 500:
          return Page500;
        default:
          return Page404;
      }
    },
  },

  render() {
    const slot = this.$slots.default() ? this.$slots.default()[0].children.default()[0] : '';
    return this.errorIsRaised ? h(this.renderErrorPage()) : slot;
  },
};
