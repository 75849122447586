import { h } from 'vue';
import * as Sentry from '@sentry/vue';
import ErrorMessage from 'Components/errorMessage/ErrorMessage.vue';
import { handleReload } from '@/router/routerMiddleware';

export default {
  components: {
    ErrorMessage,
  },
  data: () => ({
    error: false,
  }),
  watch: {
    $route() {
      this.error = false;
    },
  },
  errorCaptured(err, vm, info) {
    handleReload(err);

    if (import.meta.env.MODE === 'production') {
      Sentry.captureException(err);
    } else {
      console.error('Error:', err);  // eslint-disable-line
      console.log('In Component', vm); // eslint-disable-line
      console.log('More info:', info); // eslint-disable-line
    }

    this.error = true;
    return false; // return FALSE to prevent the error from propagating further
  },

  render() {
    const slot = this.$slots.default() ? this.$slots.default()[0] : '';
    return this.error ? h(ErrorMessage) : slot;
  },
};
