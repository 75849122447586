import ActionButton from 'Components/buttons/actionButton/ActionButton.vue';
import routes from '@/router/routes';

import error400Svg from 'Assets/images/error_400.svg';

export default {
  components: {
    ActionButton,
  },

  data() {
    return {
      error400Svg,
    };
  },

  computed: {
    textGoBack() {
      return this.$route.meta.navigationBar
        ? this.$t('general.navigation.backToDashboard')
        : this.$t('general.navigation.backToLogin');
    },
    urlGoBack() {
      return this.$route.meta.navigationBar
        ? routes('dashboard')
        : routes('login');
    },
  },
};
