import axios from 'axios';
import urls from 'Services/api/endpoints';
import setCircuitBreakerAxiosAdapter from 'Services/circuitBreaker/axiosAdapter';

import router from '@/router';
import routes from '@/router/routes';
import logout from '@/helpers/logout';
import dates from '@/helpers/dates';
import regex from '@/helpers/regex';

let axiosInstance = null;
const baseURL = '/proxy';
const errorDefault = { response: { request: {}, config: {} } };

const ensureUrlArray = (urlSet) => (regex.url(urlSet) ? [urlSet] : urlSet);

const mergeConfigurationWithHeaders = (leadingConfig = {}, fallbackConfig = {}) => {
  const { headers, ...leadingRemains } = leadingConfig;
  return {
    ...fallbackConfig,
    ...(headers ? { headers: { ...fallbackConfig.headers, ...headers } } : {}),
    ...leadingRemains,
  };
};

class Axios {
  constructor() {
    this.http = axios.create({
      baseURL,
      timeout: 30000,
      xsrfCookieName: 'csrftoken',
      xsrfHeaderName: 'X-CSRFToken',
      withCredentials: true,
    });
    setCircuitBreakerAxiosAdapter(this.http);
    this.setRequestInterceptors();
    this.setResponseInterceptors();
  }

  setResponseInterceptors() {
    const onSuccess = (config) => {
      const { 'session-expires': sessionExpires } = config.headers;
      if (sessionExpires) localStorage.setItem('session_expires', sessionExpires);
      return config;
    };
    const onError = async (error = errorDefault) => {
      if (!error.response) return new Promise(() => {});
      const [logout_on_legacy_platform] = urls('logout_on_legacy_platform');
      const [questionnaire_informant_assignment] = urls('questionnaire_informant_assignment');
      const [validate_auth_token] = urls('validate_auth_token');

      const { responseURL } = error.response.request;
      const { status, config } = error.response;
      const { name } = router.currentRoute.value;

      if (
        (
          name !== routes('login')
            && config.url !== logout_on_legacy_platform
            && (status === 401 || status === 504)
        ) && !responseURL.includes(validate_auth_token) // SSO connection from 1.0
            && !responseURL.includes(questionnaire_informant_assignment) // Questionnaire informant
            && name !== routes('register_organisation_slug') // Register under self help organisation
      ) {
        if (localStorage.getItem('is_logging_out')) return new Promise(() => {});
        await logout();
      }
      return Promise.reject(error);
    };

    this.http.interceptors.response.use(onSuccess, onError);
  }

  setRequestInterceptors() {
    const onSuccess = (config) => {
      const controller = new AbortController();
      const { auth_required } = config.headers;
      const sessionExpired = dates.isBefore({
        firstDate: localStorage.getItem('session_expires'),
      });

      if (sessionExpired && auth_required) {
        if (!localStorage.getItem('is_logging_out')) logout();
        controller.abort();
      }

      return {
        ...config,
        signal: controller.signal,
      };
    };

    this.http.interceptors.request.use(onSuccess, undefined);
  }

  post(urlSet, data = {}, config = {}) {
    const [url, urlConfig = {}] = ensureUrlArray(urlSet);
    return this.http.post(url, data, mergeConfigurationWithHeaders(config, urlConfig));
  }

  get(urlSet, params = {}) {
    const [url, config = {}] = ensureUrlArray(urlSet);
    return this.http.get(url, mergeConfigurationWithHeaders(config, params));
  }

  delete(urlSet) {
    const [url, config = {}] = ensureUrlArray(urlSet);
    return this.http.delete(url, config);
  }

  put(urlSet, data = {}, config = {}) {
    const [url, urlConfig = {}] = ensureUrlArray(urlSet);
    return this.http.put(url, data, mergeConfigurationWithHeaders(config, urlConfig));
  }

  patch(urlSet, data = {}, config = {}) {
    const [url, urlConfig = {}] = ensureUrlArray(urlSet);
    return this.http.patch(url, data, mergeConfigurationWithHeaders(config, urlConfig));
  }
}

const getOrCreateInstance = () => {
  if (!axiosInstance) axiosInstance = new Axios();
  return axiosInstance;
};

export default getOrCreateInstance();
