import axios from 'Services/api/base';
import urls from 'Services/api/endpoints';

import router from '@/router';
import store from '@/store/store';
import routes from '@/router/routes';
import { legacySessionTokenExists } from '@/helpers/legacy';

export default async ({ url = routes('login'), cancelRedirect = false } = {}) => {
  localStorage.setItem('is_logging_out', true);
  const logoutPosts = [
    () => axios.post(urls('logout')),
    () => axios.post(urls('logout_on_legacy_platform')),
  ];

  if (!legacySessionTokenExists()) logoutPosts.pop();

  return Promise.all(logoutPosts.map((post) => post()))
    .finally(async (response) => {
      if (!cancelRedirect) await router.push(url);
      await store.dispatch('resetState');

      const pendoReady = await window.pendo?.isReady();
      if (import.meta.env.VITE_PENDO && pendoReady) {
        await window.pendo.clearSession();
      }
      localStorage.removeItem('session_expires');
      localStorage.removeItem('is_logging_out');
      return response;
    });
};
