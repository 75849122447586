export default {
  props: {
    action: {
      type: Function,
      default: () => {},
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'icon button',
    },
  },
};
