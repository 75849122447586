export default {
  // USER ROLES
  PATIENT: 'patient',
  PROFESSIONAL: 'professional',

  // ORGANISATION ROLES
  ORGANISATION_CARE_GIVER: 'organisation_care_giver',
  ORGANISATION_ADMIN: 'organisation_admin',

  // PARTICIPANT ROLES
  CARE_GIVER: 'care_giver',
  CARE_GIVER_LIMITED_ACCESS: 'care_giver_limited_access',
  CARE_TAKER: 'care_taker',
};
