<template>
  <div
    class="badge"
    :class="{ [`badge--${variant}`]: variant }"
  >
    {{ number }}
  </div>
</template>

<script src="./badgeComponent.js"></script>

<style lang="scss">
  .badge {
    width: 2rem;
    height: 2rem;
    background-color: $critical-40;
    border-radius: 50rem;
    font-size: $font-size-extra-small;
    color: $neutral-0;
    display: flex;
    align-items: center;
    justify-content: center;

    &--primary {
      background-color: $primary-80;
    }
    &--secondary {
      background-color: $secondary-80;
    }
    &--info {
      background-color: $info-80;
    }
    &--critical {
      background-color: $critical-40;
    }
    &--success {
      background-color: $success-80;
    }
    &--warning {
      background-color: $warning-80;
    }
    &--neutral {
      background-color: $neutral-100;
    }
  }
</style>
