<template>
  <div class="loader">
    <IconComponent name="spinner" />
  </div>
</template>

<script src="./loaderComponent.js"></script>

<style lang="scss">
  .loader {
    z-index: map-get($zindex, modal);
    .icon-component {
      svg {
        animation-name: spin;
        animation-duration: 1500ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
  }
</style>
