import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { auth } from './modules/auth';
import { carePlanCreate } from './modules/carePlanCreate';
import { enrollments } from './modules/enrollments';
import { errors } from './modules/errors';
import { redirectQueue } from './modules/redirectQueue';

import actions from './actions';
import mutations from './mutations';

const store = createStore({
  mutations,
  actions,
  modules: {
    auth,
    carePlanCreate,
    enrollments,
    errors,
    redirectQueue,
  },
  plugins: [
    createPersistedState({
      paths: [
        'auth',
        'enrollments',
        'redirectQueue',
      ],
    }),
  ],
  strict: import.meta.env.MODE !== 'production',
});

export default store;
