export default {
  props: {
    action: {
      type: Function,
      default: () => {},
    },
    name: {
      type: String,
      default: '',
    },
    isUnderlined: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      validator: (prop) => [
        'primary',
        'secondary',
        'info',
        'critical',
        'success',
        'warning',
        'error',
        'neutral',
      ].includes(prop),
      default: 'primary',
    },
  },
};
