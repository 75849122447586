<template>
  <div class="access-denied">
    <img
      class="access-denied__image"
      :src="boySittingSvg"
      :alt="$t('general.errors.accessDenied')"
    >
    <div class="access-denied__content">
      <h1>
        <span class="access-denied__title">403</span>
        {{ $t('general.words.error') }}
      </h1>
      <h3 class="access-denied__access-denied">
        {{ $t('general.errors.accessDenied') }}...
      </h3>
      <span
        v-sanitize-html="$t('general.errors.needHelp')"
        class="access-denied__support"
      />
      <ActionButton
        :to="urlGoBack"
        is-secondary
      >
        {{ textGoBack }}
      </ActionButton>
    </div>
  </div>
</template>

<script src="./403View.js"></script>

<style lang="scss">
  .access-denied {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 10rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    &__content {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: 3rem;

      @include media-breakpoint-up(md) {
        text-align: left;
        margin-left: 3rem;
        margin-top: 0;
      }
    }

    &__image {
      max-width: 25rem;
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: 25rem;
      }
    }

    &__access-denied {
      margin: 0;
    }

    &__support {
      color: $neutral-60;
      margin-bottom: 7rem;
    }

    &__title {
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-huge;
    }
  }
</style>
