<template>
  <div class="internal-error">
    <img
      class="internal-error__image"
      :src="error500Svg"
      :alt="$t('general.errors.internalError')"
    >
    <div class="internal-error__information">
      <span class="internal-error__title">
        500 <span class="internal-error__title--error">{{ $t('general.words.error') }}</span>
      </span>
      <h2 class="internal-error__sub-title">
        {{ $t('general.errors.internalError') }}...
      </h2>
      <span
        v-sanitize-html="$t('general.errors.needHelp')"
        class="internal-error__support"
      />
      <span
        v-if="internalServerErrorCode"
        class="internal-error__code"
      >
        {{ $t('general.errors.issueReference', { code: internalServerErrorCode }) }}
      </span>
      <ActionButton
        :to="urlGoBack"
        is-secondary
      >
        {{ textGoBack }}
      </ActionButton>
    </div>
  </div>
</template>

<script src="./500View.js"></script>

<style lang="scss">
  .internal-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 65rem;
    margin: 0 auto;
    height: 60rem;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    &__image {
      width: 100%;
      max-height: 22rem;
      @include media-breakpoint-up(md) {
        max-height: 43rem;
      }
    }

    &__information {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      @include media-breakpoint-up(md) {
        align-items: self-start;
      }
    }

    &__title {
      font-size: $font-size-huge;
      font-weight: $font-weight-semi-bold;
      &--error {
        font-size: $font-size-extra-large;
        font-weight: $font-weight-semi-bold;
        text-transform: uppercase;
      }
    }

    &__support {
      color: $neutral-60;
      margin-bottom: 7rem;
    }

    &__sub-title {
      margin-bottom: 1rem;
      font-weight: $font-weight-regular;
    }

    &__code {
      position: fixed;
      bottom: 1rem;
      left: 5rem;
      color: $neutral-60;
    }
  }
</style>
