<template>
  <div class="not-found">
    <img
      class="not-found__image"
      :src="error400Svg"
      :alt="$t('general.errors.notFound')"
    >
    <div class="not-found__information">
      <span class="not-found__title">404
        <span class="not-found__title--error"> {{ $t('general.words.error') }}</span>
      </span>
      <h2 class="not-found__sub-title">
        {{ $t('general.errors.notFound') }}...
      </h2>
      <span
        v-sanitize-html="$t('general.errors.needHelp')"
        class="not-found__support"
      />
      <ActionButton
        :to="urlGoBack"
        is-secondary
      >
        {{ textGoBack }}
      </ActionButton>
    </div>
  </div>
</template>

<script src="./404View.js"></script>

<style lang="scss">
  .not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 65rem;
    margin: 0 auto;
    height: 60rem;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    &__image {
      width: 100%;
      max-width: 13rem;
      @include media-breakpoint-up(md) {
        max-width: 26rem;
      }
    }

    &__information {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      @include media-breakpoint-up(md) {
        align-items: self-start;
        margin-left: 3rem;
      }
    }

    &__title {
      font-size: $font-size-huge;
      font-weight: $font-weight-semi-bold;
      &--error {
        font-size: $font-size-extra-large;
        font-weight: $font-weight-semi-bold;
        text-transform: uppercase;
      }
    }

    &__support {
      color: $neutral-60;
      margin-bottom: 7rem;
    }

    &__sub-title {
      margin-bottom: 1rem;
      font-weight: $font-weight-regular;
    }
  }
</style>
