import { mapGetters } from 'vuex';

import ActionButtonIcon from 'Components/buttons/actionButtonIcon/ActionButtonIcon.vue';
import BadgeComponent from 'Components/badges/badgeComponent/BadgeComponent.vue';
import IconComponent from 'Components/icons/iconComponent/IconComponent.vue';
import ImageMain from 'Components/imageMain/ImageMain.vue';
import SupportButton from 'Components/supportButton/SupportButton.vue';

import routes from '@/router/routes';
import logout from '@/helpers/logout';

import iconRound from 'Static/logos/icon_round.svg';

import ButtonHamburger from '../buttonHamburger/ButtonHamburger.vue';
import ButtonNotifications from '../buttonNotifications/ButtonNotifications.vue';
import ButtonOrganisations from '../buttonOrganisations/ButtonOrganisations.vue';
import NavigationActionBar from '../navigationActionBar/NavigationActionBar.vue';

export default {
  components: {
    ActionButtonIcon,
    BadgeComponent,
    ButtonHamburger,
    ButtonNotifications,
    ButtonOrganisations,
    IconComponent,
    ImageMain,
    NavigationActionBar,
    SupportButton,
  },

  props: {
    newMessagesCount: {
      type: Number,
    },
    newNotificationsCount: {
      type: Number,
    },
    links: {
      type: Array,
      required: true,
    },
    canNavigate: {
      type: Boolean,
    },
  },

  data() {
    return {
      iconRound,
      logout,
      menuToggled: false,
      routes,
    };
  },

  computed: {
    ...mapGetters('enrollments', ['activeOrganisation']),
    ...mapGetters('auth', ['isPatient']),
  },

  methods: {
    closeMenu() {
      this.menuToggled = false;
    },

    toggleMenu() {
      this.menuToggled = !this.menuToggled;
    },
  },
};
