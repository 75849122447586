import _refiner from 'refiner-js';

export const setRefinerProject = () => {
  _refiner('setProject', `${import.meta.env.VITE_REFINER_PROJECT_ID}`);
};

export const identifyRefinerUser = ({
  id, role, locale, current_platform,
}) => {
  _refiner('identifyUser', {
    id,
    role,
    locale,
    current_platform,
  });
};

export const addResponseToRefiner = ({ surveyId, treatmentSlug } = {}) => {
  const options = {
    platform: window.location.hostname,
    url: '__CURRENT_URL__',
  };

  if (surveyId) options.survey_identifier = surveyId;
  if (treatmentSlug) options.treatment_slug = treatmentSlug;

  _refiner('addToResponse', options);
};

export const showFormRefiner = (surveyId) => {
  _refiner('showForm', surveyId, true);
};

export const embedRefiner = (surveyId, elementId) => {
  _refiner('embed', surveyId, elementId);
};

export const resetUserRefiner = () => {
  _refiner('resetUser');
};

export const onCompleteRefiner = (callback) => {
  _refiner('onComplete', callback);
};

export const setLocaleRefiner = (locale) => {
  _refiner('setLocale', locale);
};

export const trackUserOnLastTreatmentStep = () => {
  _refiner('trackEvent', 'UserIsOnLastTreatmentStep');
};

export const trackProfessionalArchivedUser = () => {
  _refiner('trackEvent', 'ProfessionalArchivedUser');
};

export const trackUserHasStartedProgram = () => {
  _refiner('trackEvent', 'UserHasStartedProgram');
};

export const trackProfessionalHasInvitedNewClient = () => {
  _refiner('trackEvent', 'ProfessionalHasInvitedNewClient');
};

export const trackProfessionalSendVideoInvite = () => {
  _refiner('trackEvent', 'ProfessionalSendVideoInvite');
};
