let isBottomReached = false;

export default (
  { scrollTop, scrollHeight, clientHeight },
  callback,
  options = { percentage: 0.9 },
) => {
  // Execute the callback when the scroll reached a percentage
  // of the total height (by default 90%)
  const { percentage } = options;
  const scrollPosition = scrollTop + clientHeight;
  if (scrollPosition <= scrollHeight * percentage) isBottomReached = false;

  if (scrollPosition > (scrollHeight * percentage) && !isBottomReached) {
    isBottomReached = true;
    callback();
  }
};
