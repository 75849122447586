import { mapActions, mapGetters } from 'vuex';

import IconComponent from 'Components/icons/iconComponent/IconComponent.vue';
import ProfileAvatar from 'Components/profileAvatar/ProfileAvatar.vue';

import routes from '@/router/routes';
import logout from '@/helpers/logout';
import urls from 'Services/api/endpoints';

export default {
  components: {
    IconComponent,
    ProfileAvatar,
  },

  data() {
    return {
      routes,
      show: false,
      logout,
    };
  },

  computed: {
    ...mapGetters('enrollments', ['activeOrganisation', 'enrollments']),

    inactiveEnrollments() {
      return this.enrollments
        .filter(({ organisation }) => organisation.id !== this.activeOrganisation.id);
    },
  },

  methods: {
    ...mapActions('enrollments', ['setActiveEnrollment']),

    toggleMenu() {
      this.show = !this.show;
    },

    closeMenu() {
      this.show = false;
    },

    getOrganisationInitials(name) {
      if (!name) return '';
      return `${name.charAt(0).toUpperCase()}${name.charAt(1).toUpperCase()}`;
    },

    selectOrganisation(enrollmentId) {
      this.$axios.post(urls('enrollments_selected', enrollmentId))
        .then(() => {
          this.setActiveEnrollment(enrollmentId);
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: this.$t('general.formGeneralError.tryLater'),
          });
        });
    },
  },
};
