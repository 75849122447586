import moment from 'moment';

export default {
  date: (date, format = 'DD/MM/YYYY') => moment(date)
    .format(format),

  dateFromWeek: (week, date = new Date()) => moment(date)
    .startOf('isoWeek')
    .week(week),

  dateTime: (date) => moment(date, 'DD/MM/YYYY HH:mm')
    .format(),

  day: (date = new Date(), format = 'dddd') => moment(date)
    .format(format),

  diff: (date = new Date(), diffDate = new Date(), measurement = '') => (
    moment(date).diff(diffDate, measurement)
  ),

  firstDayNextMonth: (date) => moment(date)
    .add(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD'),

  firstDayNextWeek: (date) => moment(date)
    .add(1, 'weeks')
    .day(1)
    .format('YYYY-MM-DD'),

  firstDayPreviousMonth: (date) => moment(date)
    .subtract(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD'),

  firstDayPreviousWeek: (date) => moment(date)
    .subtract(1, 'weeks')
    .day(1)
    .format('YYYY-MM-DD'),

  firstMonthDay: (date) => moment(date)
    .startOf('month')
    .format('YYYY-MM-DD'),

  format: (date = new Date(), format = '') => moment(date)
    .format(format),

  fromUTC: (date = new Date(), format = '') => moment
    .utc(date)
    .local()
    .format(format),

  hour: (date = new Date(), format = 'HH') => moment(date)
    .format(format),

  isBetween: (start, end, date = new Date()) => moment(date)
    .isBetween(start, end),

  isSameOrBefore: ({ firstDate = new Date(), secondDate = new Date() }) => (
    moment(firstDate).isSameOrBefore(secondDate)
  ),

  isBefore: ({ firstDate = new Date(), secondDate = new Date() }) => (
    moment(firstDate).isBefore(secondDate)
  ),

  isToday: (date) => moment()
    .isSame(date, 'day'),

  lastDayNextMonth: (date) => moment(date)
    .add(1, 'months')
    .endOf('month')
    .format('YYYY-MM-DD'),

  lastDayNextWeek: (date) => moment(date)
    .add(1, 'weeks')
    .day(7)
    .format('YYYY-MM-DD'),

  lastDayPreviousMonth: (date) => moment(date)
    .subtract(1, 'months')
    .endOf('month')
    .format('YYYY-MM-DD'),

  lastDayPreviousWeek: (date) => moment(date)
    .subtract(1, 'weeks')
    .day(7)
    .format('YYYY-MM-DD'),

  lastMonday: (date, format = 'YYYY-MM-DD', isUtcDisabled = true) => moment(date)
    .day(1)
    .startOf('day')
    .utc(isUtcDisabled) // utc() receives a boolean to disable it. utc(is_disabled)
    .format(format),

  lastMonthDay: (date) => moment(date)
    .endOf('month')
    .format('YYYY-MM-DD'),

  mins: (date = new Date(), format = 'mm') => moment(date)
    .format(format),

  minusDays: (days = 0, date = new Date(), format = 'YYYY-MM-DD') => (
    moment(date).subtract(days, 'days').format(format)
  ),

  mondayAddWeek: (date = new Date(), index = 0) => moment(date)
    .add(index, 'weeks')
    .startOf('isoWeek'),

  month: (date = new Date(), format = 'MM') => moment(date)
    .format(format),

  nextSunday: (date, format = 'YYYY-MM-DD', isUtcDisabled = true) => moment(date)
    .day(7)
    .endOf('day')
    .utc(isUtcDisabled) // utc() receives a boolean to disable it. utc(is_disabled)
    .format(format),

  plusDays: (days = 0, date = new Date(), format = 'YYYY-MM-DD') => (
    moment(date).add(days, 'days').format(format)
  ),

  sundayAddWeek: (date = new Date(), index = 0) => moment(date)
    .add(index, 'weeks')
    .endOf('isoWeek'),

  time: (date = new Date(), format = 'HH:mm') => moment(date)
    .format(format),

  today: (format = 'DD/MM/YYYY') => moment()
    .format(format),

  week: (date = new Date()) => moment(date)
    .startOf('isoWeek')
    .week(),

  year: (date = new Date(), format = 'YYYY') => moment(date)
    .format(format),
};
