export const SET_ERROR_STATUS_CODE = 'SET_ERROR_STATUS_CODE';
export const SET_INTERNAL_SERVER_ERROR_CODE = 'SET_INTERNAL_SERVER_ERROR_CODE';

const initialStateErrors = () => ({
  errorStatusCode: null,
  internalServerErrorCode: null,
});

const errors = {
  namespaced: true,
  state: initialStateErrors(),

  getters: {
    errorStatusCode: (state) => state.errorStatusCode,
    internalServerErrorCode: (state) => state.internalServerErrorCode,
  },

  mutations: {
    SET_ERROR_STATUS_CODE: (state, payload) => {
      state.errorStatusCode = payload;
    },
    SET_INTERNAL_SERVER_ERROR_CODE: (state, payload) => {
      state.internalServerErrorCode = payload;
    },
  },

  actions: {
    setErrorStatusCode({ commit }, code) {
      commit(SET_ERROR_STATUS_CODE, code);
    },
    setInternalServerErrorCode({ commit }, code) {
      commit(SET_INTERNAL_SERVER_ERROR_CODE, code);
    },
  },
};

export { initialStateErrors, errors };
