import DOMPurify from 'dompurify';

const TEMP_ATTR = 'data-temp-href-target';

DOMPurify.addHook('beforeSanitizeAttributes', (node) => {
  if (node.tagName === 'A') {
    if (!node.hasAttribute('target')) {
      node.setAttribute('target', '_self');
    }

    if (node.hasAttribute('target')) {
      node.setAttribute(TEMP_ATTR, node.getAttribute('target'));
    }
  }
});

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if (node.tagName === 'A' && node.hasAttribute(TEMP_ATTR)) {
    node.setAttribute('target', node.getAttribute(TEMP_ATTR));
    node.removeAttribute(TEMP_ATTR);
    if (node.getAttribute('target') === '_blank') {
      node.setAttribute('rel', 'noopener noreferrer');
    }
  }
});

const sanitize = (html, config) => DOMPurify.sanitize(html, config);
export default sanitize;
