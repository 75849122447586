import RequestContext from './requestContext';

export default class CircuitBreaker {
  #maxAttempts;

  #numAttempts = 0;

  #coolDownMillis;

  #openedTimestamp;

  #isClosed = true;

  #lastError = null;

  constructor(maxAttempts, coolDownSecs) {
    this.#maxAttempts = maxAttempts;
    this.#coolDownMillis = coolDownSecs * 1000;
  }

  request(http, config) {
    return new Promise((resolve, reject) => {
      const context = new RequestContext(http, config, resolve, reject);

      if (this.#isClosed) {
        this.closedStateRequest(context);
      } else {
        this.openStateRequest(context);
      }
    });
  }

  closedStateRequest(context) {
    context.attemptRequest()
      .then(() => { this.resetAttempts(); })
      .catch((error) => { this.recordFailure(error); });
  }

  openStateRequest(context) {
    const elapsedCoolDown = Date.now() - this.#openedTimestamp;

    if (elapsedCoolDown > this.#coolDownMillis) {
      context.attemptRequest()
        .then(() => { this.close(); })
        .catch((error) => { this.trip(error); });
    } else {
      context.failRequest(this.#lastError);
    }
  }

  recordFailure(error) {
    this.#numAttempts += 1;

    if (this.#numAttempts >= this.#maxAttempts) {
      this.trip(error);
    }
  }

  trip(error) {
    this.#isClosed = false;
    this.#openedTimestamp = Date.now();
    this.#lastError = error;
  }

  close() {
    this.#isClosed = true;
    this.#lastError = null;
    this.resetAttempts();
  }

  resetAttempts() {
    this.#numAttempts = 0;
  }
}
