import { initialStateAuth } from './modules/auth';
import { initialStateEnrollments } from './modules/enrollments';
import { initialStateErrors } from './modules/errors';
import { initialStateRedirectQueue } from './modules/redirectQueue';

export default {
  RESET_STATE: (state) => {
    Object.assign(state.auth, initialStateAuth());
    Object.assign(state.enrollments, initialStateEnrollments());
    Object.assign(state.errors, initialStateErrors());
    Object.assign(state.redirectQueue, initialStateRedirectQueue());
  },
};
