export default {
  capital: (string) => /\w*[A-Z]/g.test(string),
  email: (string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(string),
  multipleEmails: (value) => /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(value),
  figure: (string) => /(?=.*?[#_?!@$%^&*-])/g.test(string),
  characters8: (string) => /.{8,}/g.test(string),
  tel: (string) => /([0-9\s\-.]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/g.test(string),
  firstUrlItem: (string) => {
    const [, name = ''] = string.match(/\/(.*?)\//) || [];
    return name;
  },
  url: (string) => (
    /^(?:https?|ftp):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/.test(string)
    || /^\/(?:[\w-]+\/)?(?:id[\w-]+|[\w-]+\/?)$/.test(string)
  ),
};
