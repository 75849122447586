import { mapGetters } from 'vuex';

import ActionButtonIcon from 'Components/buttons/actionButtonIcon/ActionButtonIcon.vue';
import BadgeComponent from 'Components/badges/badgeComponent/BadgeComponent.vue';
import IconComponent from 'Components/icons/iconComponent/IconComponent.vue';
import NotificationMessage from 'Components/notifications/notificationMessage/NotificationMessage.vue';
import NotificationPanel from 'Components/notifications/notificationPanel/NotificationPanel.vue';

import urls from 'Services/api/endpoints';

export default {
  components: {
    ActionButtonIcon,
    BadgeComponent,
    IconComponent,
    NotificationMessage,
    NotificationPanel,
  },

  props: {
    newNotificationsCount: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      hasNextPage: true,
      notificationsAreOpen: false,
      notifications: [],
      page: 1,
    };
  },

  computed: {
    ...mapGetters('enrollments', ['activeOrganisation']),

    hasNewNotifications() {
      return !!this.newNotificationsCount;
    },

    allNotificationsAreSeen() {
      return this.notifications.every((notification) => notification.is_seen);
    },
  },

  methods: {
    getNotifications() {
      if (!this.hasNextPage) return;
      this.$axios.get(urls('notifications'), { params: { page: this.page, organisation_id: this.activeOrganisation.id } })
        .then((response) => {
          this.page += 1;

          const updatedNotifications = response.data.results.map((result) => ({
            ...result,
            organisation_id: this.activeOrganisation.id,
          }));

          this.notifications = [...this.notifications, ...updatedNotifications];
          this.hasNextPage = !!response.data.next;
          this.$emit('notifications-are-seen');
        });
    },

    resetPagination() {
      this.page = 1;
      this.hasNextPage = true;
      this.notifications = [];
    },

    closeNotifications() {
      if (!this.notificationsAreOpen) return;
      this.resetPagination();
      this.notificationsAreOpen = false;
    },

    openNotifications() {
      this.getNotifications();
      this.notificationsAreOpen = true;
    },

    toggleNotifications() {
      if (this.notificationsAreOpen) {
        this.closeNotifications();
      } else {
        this.openNotifications();
      }
    },
  },
};
