import circuitBreakerConfig from '@/services/circuitBreaker/config';

export default function setAxiosAdapter(http) {
  const defaultAdapter = http.defaults.adapter;

  http.defaults.adapter = (config) => {
    const defaultAdapterConfig = { ...config, adapter: defaultAdapter };
    const breaker = circuitBreakerConfig(config.url);
    return (
      breaker
        ? breaker.request(http, defaultAdapterConfig)
        : http.request(defaultAdapterConfig)
    );
  };
}
