<template>
  <div class="button-profile">
    <ProfileAvatar
      v-click-outside="closeMenu"
      :user="user"
      is-button
      is-small
      data-test="profile-menu"
      @click="openMenu"
    />
    <div
      v-if="show"
      class="button-profile__menu"
    >
      <router-link
        class="button-profile__menu-item"
        data-test="settings-btn"
        :to="routes('settings')"
      >
        <IconComponent name="settings" />
        <span>{{ $t('general.titles.settings') }}</span>
      </router-link>
      <button
        class="button-profile__menu-item"
        data-test="logout-button"
        type="button"
        @click="() => logout()"
      >
        <IconComponent name="logOut" />
        <span>{{ $t('general.actions.logout') }}</span>
      </button>
    </div>
  </div>
</template>

<script src="./buttonProfile.js"></script>

<style lang="scss">
  .button-profile {
    display: flex;
    position: relative;
    z-index: map-get($zindex, dropdown);

    &__menu {
      position: absolute;
      top: 4rem;
      right: 0;
      width: 20rem;
      align-items: flex-start;
      background-color: $neutral-0;
      border-radius: $radius-default;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      box-shadow: 0 .1rem .4rem rgba($neutral-100, 0.24);
    }
    &__menu-item {
      display: flex;
      width: 100%;
      white-space:nowrap;
      padding: 1.5rem;
      color: $neutral-80;
      align-self: center;
      align-items: center;
      .icon-component {
        margin-right: 1.5rem;
        color: $neutral-80;
        svg {
          width: 2rem;
          height: 2rem;
        }
      }
      &:hover {
        background-color: $secondary-10;
      }
    }
  }
</style>
