import axios from 'Services/api/base';
import urls from 'Services/api/endpoints';

const SESSION_COOKIE_NAME = import.meta.env.VITE_LEGACY_SESSION_COOKIE_NAME;
const SESSION_COOKIE_DOMAIN = import.meta.env.VITE_LEGACY_SESSION_COOKIE_DOMAIN;

export function legacySessionTokenExists() {
  // The legacy session token is stored in an HTTP-only cookie. Testing for its existence is done in
  // an indirect manner, by attempting to set the cookie ourselves and checking if it succeeds.
  // Setting the cooking will fail if an HTTP-only cookie with the same name already exists.
  document.cookie = `${SESSION_COOKIE_NAME}=;domain=${SESSION_COOKIE_DOMAIN};path=/`;
  return document.cookie.indexOf(`${SESSION_COOKIE_NAME}=`) === -1;
}

export function performLegacyLogin() {
  return axios.post(urls('login_on_legacy_platform'));
}

export function checkLegacyAccess() {
  if (legacySessionTokenExists()) {
    return new Promise((resolve) => { resolve(); });
  }

  return performLegacyLogin();
}
