<template>
  <div
    class="desktop-navigation"
    data-test="desktop-navigation"
  >
    <router-link
      v-for="(link, index) in links"
      :key="index"
      :ref="link.url"
      class="desktop-navigation__navigation-link"
      :class="{
        'desktop-navigation__navigation-link--active': $route.path.match(link.url),
        'desktop-navigation__navigation-link--responsive': link.isResponsive,
      }"
      :to="link.url"
      :data-test="`navbar-tab-${link.icon}`"
    >
      <IconComponent :name="link.icon" />
      <span>{{ $tc(...link.labelArgs) }}</span>
    </router-link>

    <span
      v-if="!activeLineHidden"
      class="desktop-navigation__active-line"
      :style="{
        width: `${activeLineWidth}px`,
        transform: `translateX(${activeLineOffset}px)`,
      }"
    />
  </div>
</template>

<script src="./desktopNavigation.js"></script>

<style lang="scss">
  .desktop-navigation {
    position: relative;
    display: flex;

    &__navigation-link {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 2rem;
      border-bottom: .2rem solid transparent;
      height: 100%;
      color: $secondary-60;
      transition: color .3s;

      > span {
        display: inline-block;
        &:first-letter{
          text-transform: uppercase;
        }
      }

      .icon-component {
        display: flex;
        align-self: center;
        width: 2rem;
        margin-right: 1rem;
        svg {
          fill: $primary-60;
        }
      }

      &:hover {
        color: $primary-60;

        .icon-component svg {
          fill: $primary-60;
        }
      }
    }

    &__navigation-link--active {
      color: $primary-60;
    }

    &__navigation-link--responsive {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &__active-line {
      position: absolute;
      bottom: 0;
      display: inline-block;
      height: 3px;
      background-color: $primary-60;
      transition: transform 0.4s ease, width 0.4s ease;
    }
  }
</style>
