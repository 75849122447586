import store from '@/store/store';

export default (meta) => {
  let hasRolAccess = true;
  let hasMigrationAccess = true;
  let hasUserAccess = true;
  const { forRoles, migrationRequired, userAccess } = meta;

  if (migrationRequired) hasMigrationAccess = store.getters['auth/isMigrated'];
  if (forRoles) hasRolAccess = meta.forRoles.some((role) => store.getters['auth/userRoles'][role]);
  if (userAccess !== undefined) hasUserAccess = meta.userAccess();

  return hasRolAccess && hasMigrationAccess && hasUserAccess;
};
