<template>
  <button
    class="btn-inline"
    :class="{
      'btn-inline--underlined': isUnderlined,
      'btn-inline--small': isSmall,
      'btn-inline--no-padding': noPadding,
      [`btn-inline--${variant}`]: variant,
      'btn-inline--disabled': isDisabled,
    }"
    type="button"
    :name="name"
    :disabled="isDisabled"
    @click="action()"
  >
    <slot />
  </button>
</template>

<script src="./actionButtonInline.js"></script>

<style lang="scss">
 .btn-inline {
    min-width: 9.3rem;
    height: 4.5rem;
    padding: 0 1rem;
    font-size: 1.8rem;
    color: $primary-60;
    border-radius: 0.5rem;
    white-space: nowrap;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: $secondary-20;
    }

    &--underlined {
        text-decoration: underline;
    }

    &--disabled,
    &--disabled:hover {
      background-color: transparent;
      opacity: 0.4;
    }

    &--small {
      font-size: 14px;
    }

    &--no-padding {
      padding: 0;
      min-width: auto;
    }

    &--secondary {
      color: $secondary-60;
    }
    &--info {
      color: $info-60;
    }
    &--warning {
      color: $warning-60;
    }
    &--success {
      color: $success-60;
    }
    &--critical {
      color: $critical-20;
    }
    &--error {
      color: $critical-60;
    }
    &--neutral {
      color: $neutral-60;
    }
  }
</style>
