import IconComponent from 'Components/icons/iconComponent/IconComponent.vue';

export default {
  props: {
    size: {
      type: Number,
      default: 4.8,
    },
    url: {
      type: String,
    },
    isCircle: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      validator(value) {
        return [
          'treatment',
          'clock',
          'avatar',
          'organisation',
          'questionnaire',
          'questionnaireCircle',
        ].indexOf(value) !== -1;
      },
      default: 'treatment',
    },
  },

  components: {
    IconComponent,
  },

  computed: {
    style() {
      return {
        'background-image': `url('${this.url}')`,
        height: `${this.size}rem`,
        width: `${this.size}rem`,
        'border-radius': `${this.isCircle ? 4.8 : 0}rem`,
      };
    },
  },
};
