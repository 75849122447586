import axios from 'Services/api/base';
import urls from 'Services/api/endpoints';

export const SET_ENROLLMENTS = 'SET_ENROLLMENTS';
export const SET_ACTIVE_ENROLLMENT = 'SET_ACTIVE_ENROLLMENT';
export const SET_ACTIVE_ORGANISATION = 'SET_ACTIVE_ORGANISATION';

const initialStateEnrollments = () => ({
  enrollments: [],
  activeEnrollment: {},
  activeOrganisation: {},
});

const enrollments = {
  namespaced: true,
  state: initialStateEnrollments(),

  getters: {
    activeEnrollment: (state) => state.activeEnrollment,
    activeOrganisation: (state) => state.activeOrganisation,
    enrollments: (state) => state.enrollments,
    externalVideoCallIsEnabled: (state) => state.activeEnrollment.external_video_call_enabled,
    patientsLabel: (state) => state.activeEnrollment.patient_label,
    questionnaireIsEnabled: (state) => state.activeEnrollment.questionnaires_enabled,
    recommendedTreatments: (state) => state.activeEnrollment.organisation.top_treatments,
  },

  mutations: {
    SET_ENROLLMENTS: (state, payload) => {
      state.enrollments = payload;
    },

    SET_ACTIVE_ENROLLMENT: (state, id) => {
      [state.activeEnrollment] = state.enrollments.filter((item) => item.id === id);
      if (state.activeEnrollment) state.activeOrganisation = state.activeEnrollment.organisation;
    },

    SET_ACTIVE_ORGANISATION: (state, id) => {
      state.activeOrganisation = id;
    },
  },

  actions: {
    setEnrollments({ commit }) {
      return axios
        .get(urls('enrollments'), { params: { page_size: 1000 } })
        .then((response) => { commit(SET_ENROLLMENTS, response.data.results); })
        .catch((error) => { throw error; });
    },
    setActiveEnrollment: ({ commit, dispatch, getters }, id) => {
      commit(SET_ACTIVE_ENROLLMENT, id);
      dispatch('auth/setUserRoles', getters.activeEnrollment, { root: true });
    },
    setActiveOrganisation: ({ commit }, id) => {
      commit(SET_ACTIVE_ORGANISATION, id);
    },
  },
};

export { initialStateEnrollments, enrollments };
