<template>
  <button
    class="btn-icon"
    :class="{
      'btn-icon--light': isLight,
      'btn-icon--disabled': isDisabled,
    }"
    type="button"
    :name="name"
    :disabled="isDisabled"
    @click="action()"
  >
    <slot />
  </button>
</template>

<script src="./actionButtonIcon.js"></script>

<style lang="scss">
  .btn-icon {
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease;
    border-radius: $radius-default;

    & span {
      color: $neutral-80;
    }

    &:hover {
      background-color: $secondary-20;
    }

    &--disabled,
    &--disabled:active,
    &--disabled:hover {
      cursor: not-allowed;
      background-color: transparent;
      opacity: 0.4;
      .icon-component {
        color: initial;
      }
    }
  }

  .btn-icon--light {
    & span {
      color: $neutral-60;
    }
  }
</style>
