import { createI18n } from 'vue-i18n';
import nl from './locales/nl';

// CONSTANTS
export const EN = 'en';
export const NL = 'nl';
export const languages = {
  en: 'English',
  nl: 'Nederlands',
};
const loadedLanguages = [NL];

const i18n = createI18n({
  locale: NL,
  warnHtmlInMessage: 'off',
  messages: { nl },
  silentFallbackWarn: true,
  silentTranslationWarn: true,
});

export const getLocale = () => i18n.global.locale;

// FUNCTIONS
export const getLanguageToSwitchTo = () => (getLocale() === NL ? EN : NL);
export const setLocale = (key = 'nl') => {
  i18n.global.locale = key;
  document.querySelector('html').setAttribute('lang', key);
  return key;
};

// LAZY LOADING
export const loadLanguageAsync = (lang) => {
  // If the same language
  if (i18n.global.locale === lang) {
    return Promise.resolve(setLocale(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setLocale(lang));
  }

  // If the language hasn't been loaded yet
  return import('@/i18n/locales/en')
    .then((messages) => {
      i18n.global.setLocaleMessage(lang, messages.default);
      loadedLanguages.push(lang);
      return setLocale(lang);
    });
};

export default i18n;
