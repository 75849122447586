<template>
  <div class="nav-bar-app">
    <div class="nav-bar-app__nav-bar">
      <div class="nav-bar-app__nav-bar-inner">
        <div class="nav-bar-app__content-center container">
          <div class="nav-bar-app__content">
            <div class="nav-bar-app__content-center__row nav-bar-app__content-center__row-top">
              <router-link
                class="nav-bar-app__logo"
                data-test="logo"
                :to="routes('dashboard')"
              >
                <img
                  class="nav-bar-app__logo-image nav-bar-app__logo-image-desktop"
                  :src="fullSvg"
                >
              </router-link>

              <NavigationActionBar
                :can-navigate="canNavigate"
                :new-messages-count="newMessagesCount"
                :new-notifications-count="newNotificationsCount"
                @notifications-are-seen="requestUnreadNotifications"
              />
            </div>

            <div class="nav-bar-app__content-center__row nav-bar-app__content-center__row-bottom">
              <DesktopNavigation
                v-if="canNavigate"
                :links="accessibleLinks"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <NavBarMobile
      :links="accessibleLinks"
      :can-navigate="canNavigate"
      :new-messages-count="newMessagesCount"
      :new-notifications-count="newNotificationsCount"
      @request-unread-notifications="requestUnreadNotifications"
    />
  </div>
</template>

<script src="./navBarApp.js"></script>

<style lang="scss">
  .nav-bar-app {
    width: 100%;
    position: relative;
    height: $app-header-height-mobile;

    @include media-breakpoint-up(lg) {
      height: $app-header-height-desktop;
      margin-top: $spacing-default * .8;
    }

    .container {
      min-height: 0;
      margin-top: 0;
    }

    &__nav-bar {
      display: none;
      background: $neutral-0;
      box-shadow: 0 .3rem 1rem 0 rgba(214, 214, 214, 0.16);
      z-index: map-get($zindex, navigation);

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &__nav-bar-inner {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
    }

    &__language {
      position: absolute;
      top: 6rem;
      right: 0;

      @include media-breakpoint-up(md) {
        right: 4rem;
      }

      @include media-breakpoint-up(xl) {
        right: 8rem;
      }
    }

    &__content-center__row {
      display: flex;
    }

    &__content-center__row-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 4.5rem;
      margin-bottom: $spacing-default * .8;
    }

    &__content-center__row-bottom {
      margin-bottom: 0;
    }

    &__content-center .nav-bar-app__logo {
      margin-right: $spacing-default * 3.8;
    }

    &__logo {
      display: flex;
      height: 100%;
      align-items: flex-start;
    }

    &__logo-image {
      width: 16rem;
    }
  }
</style>
