<template>
  <span class="icon-component">
    <component :is="name" />
  </span>
</template>

<script src="./iconComponent.js"></script>

<style lang="scss">
  .icon-component {
    display: inline-block;
    width: 2.4rem;

    svg {
      width: 100%;
      height: auto;
    }
  }
</style>
