import { mapGetters } from 'vuex';

import ActionButton from 'Components/buttons/actionButton/ActionButton.vue';
import ActionButtonIcon from 'Components/buttons/actionButtonIcon/ActionButtonIcon.vue';
import ActionButtonInline from 'Components/buttons/actionButtonInline/ActionButtonInline.vue';
import BadgeComponent from 'Components/badges/badgeComponent/BadgeComponent.vue';
import IconComponent from 'Components/icons/iconComponent/IconComponent.vue';
import ImageMain from 'Components/imageMain/ImageMain.vue';
import SupportButton from 'Components/supportButton/SupportButton.vue';

import routes from '@/router/routes';
import ButtonNotifications from '../buttonNotifications/ButtonNotifications.vue';
import ButtonOrganisations from '../buttonOrganisations/ButtonOrganisations.vue';
import ButtonProfile from '../buttonProfile/ButtonProfile.vue';

export default {
  components: {
    ActionButton,
    ActionButtonIcon,
    ActionButtonInline,
    BadgeComponent,
    ButtonNotifications,
    ButtonOrganisations,
    ButtonProfile,
    IconComponent,
    ImageMain,
    SupportButton,
  },
  props: {
    canNavigate: {
      type: Boolean,
      default: true,
    },
    newMessagesCount: {
      type: Number,
      default: 0,
    },
    newNotificationsCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      routes,
    };
  },

  computed: {
    ...mapGetters('enrollments', ['activeOrganisation']),
    ...mapGetters('auth', ['isPatient']),

    hasNewMessages() {
      return !!this.newMessagesCount;
    },
  },
};
