import redirectToSupportPage from '@/helpers/redirectSupportPage';

import ActionButtonIcon from 'Components/buttons/actionButtonIcon/ActionButtonIcon.vue';
import IconComponent from 'Components/icons/iconComponent/IconComponent.vue';

export default {
  components: {
    ActionButtonIcon,
    IconComponent,
  },
  data() {
    return {
      redirectToSupportPage,
    };
  },
};
